<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
        <a-button  type='primary' @click='handleNew();'>新增渠道号</a-button>
      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' :showPagination="false">

        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
              <a @click='handleNew(record)'>编辑</a>
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                            @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>
    <a-modal :title="deliver.form.id ?'编辑渠道号':'新增渠道号'" :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
             @cancel='()=>this.deliver.visible=false'
             ok-text='提交' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='渠道号名称:' prop='name'>
          <a-input v-model='deliver.form.name' />
        </a-form-model-item>

        <a-form-model-item label='appid:' prop='appid'>
          <a-input v-model='deliver.form.appid' />
        </a-form-model-item>

        <a-form-model-item label='密钥:' prop='secret' >
          <a-input v-model='deliver.form.secret' />
        </a-form-model-item>
        <a-form-model-item label='小程序appid:' prop='miniAppid' >
          <a-input v-model='deliver.form.miniAppid' />
        </a-form-model-item>
        <a-form-model-item label='渠道号:' prop='mchid'>
          <a-input v-model='deliver.form.mchid' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {
  channelAccount, addChannelAccount, deleteChannelAccount
} from '@/api/account'
import client from '@/config/oss.config.js'

const columns = [
  {
    width: 150,
    title: '渠道号名称',
    dataIndex: 'name'
  },
  {
    width: 150,
    title: 'appid',//乐刷appid
    dataIndex: 'appid',
    scopedSlots: { customRender: 'appid' }
  },
  {
    width: 150,
    title: '小程序appid',
    dataIndex: 'miniAppid'
  },
  {
    width: 150,
    title: '渠道号',
    dataIndex: 'mchid'
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Account',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      loading: false,
      // 表头
      columns,
      form: {
        keyword: ""
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter,this.form)
        return channelAccount(params).then(datum => {
            this.$previewRefresh();
            return {
              records: datum.records
            }
          })
      },
      deliver: { 
        loading: false,
        visible: false, 
        form: {},
        rules: {
          name: [{ required: true, message: '请填写渠道号名称', trigger: 'change' }],
          appid: [{ required: true, message: '输入appid', trigger: 'change' }],
          mchid: [{ required: true, message: '请填写商户号', trigger: 'change' }],
          secret: [{ required: true, message: '请填写密钥', trigger: 'change' }],
          miniAppid: [{ required: true, message: '请填小程序appid', trigger: 'change' }],
        } 
      }, 
    }
  },
  created() {
  },
  methods: {
    async handleUpload(event, key) {
      this.loading = true
      let result = await client.put('account/' + event.file.uid, event.file)
      this.$set(this.deliver.form, key, result.url)
      this.loading = false
      this.$refs.form.clearValidate()
    },
    handleNew(record) {
      // debugger
      if(record){
        this.deliver.form = Object.assign( record);
      }
      this.deliver.visible = true;
      this.deliver.loading = false;

    },
    handleDeliver() {
      this.$refs.form.validate(result => {
       if (result) {
        this.deliver.loading = true;
         addChannelAccount(this.deliver.form).then(() => {
          this.$message.success('操作成功')
          this.deliver.visible = false;
          this.$refs.table.refresh(true)
          this.deliver.loading = false;
        }).catch(() => {
          this.deliver.loading = false;
        })
       }
      })
    },
    handleDelete(record) {
      deleteChannelAccount({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
  }
}
</script>
